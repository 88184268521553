<template>
  <div class="row">
    <template v-if="order && (order.product?.sku?.includes('SH') || order.product?.sku?.includes('SM'))">
      <div class="col-12 text-right">
        <router-link v-bind:to="{ name: 'DeviceTesting', query: {id_number: order.id_number } }" class="btn btn-warning btn-md">
          <i class="fa fa-tasks mr-2"></i>
          Run Devices Test</router-link> 
        </div>
    </template>
    <div class="col-12 col-md-3">
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">
            <router-link v-bind:to="{ name: 'Production' }" class="btn btn-outline-secondary btn-md mr-4"><i class="fa fa-arrow-left"></i></router-link>
            Details
          </h4>
        </div>
        <div class="card-body" v-if="order">
          <div class="row details">
            <div class="col-12 col-md-12">
              <router-link v-if="isCompleted" v-bind:to="{ name: 'ProductionOrderCertificate', params: { id: order.id_number }}" 
                class="btn ups btn-sm mb-4" target="_blank">
                <i class="fa fa-file mr-1"></i> Download Certificate
              </router-link>
              <div>
                <strong>ID Number:</strong> {{ order.id_number }}
                <br>
                <kbd class="kbd" v-if="order.sap_document_id">{{ order.sap_document_id }}</kbd>
              </div>
              <div v-if="$router.user.roleScore > 1">
                <strong>Assembler:</strong> {{ order.assembler }}
              </div>
              <div v-if="$router.user.roleScore > 1">
                <strong>Warehouse:</strong> {{ order.warehouse }}
              </div>
              <div v-if="order.operators">
                <strong>Operators ID:</strong> {{ order.operators.join(',') }}
              </div>
              <div v-if="order.sap_id">
                <strong>SAP ID:</strong> {{ order.sap_id }}
              </div>
              <div>
                <strong>Status:</strong>
                <span class="status ml-2" :class="order.status">{{ order.status }}</span>
                <kbd class="ml-2">{{ order.typology}}</kbd>
              </div>
              <div>
                <i class="fas fa-calendar"></i> <strong>Due Date:</strong>
                {{ order.date | formatDate }}
              </div>
              <small>
                <strong>Created at:</strong>
                {{ order.createdAt | formatDate }}
              </small>
              <br>
              <small>
                <strong>Last update:</strong>
                {{ order.updatedAt | formatDate }}
              </small>
            </div>
            <div class="col-12 col-md-12 mt-3">
              <div class="form-inline justify-content-between">
                <strong>Product:</strong>
                <div v-if="order.product" class="bullet" :style="`background-color: ${colors[order.product.commercial_code]}`"></div>
                <!-- <BOM class="mt-2" :sku="order.product.sku"></BOM> -->
              </div>
              <table class="product">
                <tr v-for="(value, key, index) in order.product" :key="index" :class="key">
                  <td><b>{{ key | label }}:</b></td>
                  <td>{{ value }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12">
              <div v-if="order.notes" class="notes">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                <strong>Notes:</strong> {{ order.notes }}
              </div>
            </div>
          </div>
          <div class="row mt-3" v-if="order.typology === 'production' || isReworkOrder">
            <div class="col-12 text-center" v-if="!order.product_serials || !order.product_serials.length">
              <button class="btn btn-secondary btn-block" @click="startProduction()">
                <i class="fa fa-barcode mr-2"></i>
                <span>Generate Serials</span>
              </button>
            </div>
            <template v-else>
              <div class="col-12">
                <download-csv class="btn print" delimiter=";" :data="labelsCsvData" :name="order.id_number + '_Labels.csv'">
                  <div class="icons">
                    <i class="fa fa-2x fa-file-excel"></i><i class="fa fa-arrow-right"></i><i class="fa fa-2x fa-print"></i>
                  </div>
                  Download & Print Labels
                </download-csv>
              </div>
              <div class="col-12">
                <label>Main Product Serials</label>
                <ul>
                  <li v-for="srl in order.product_serials" :key="srl">
                    <span :class="{strike: order.serials.find((serial) => serial['product'] === srl) || order.nc_products.find((serial) => serial['product'] === srl)}">{{ srl }}</span>
                  </li>
                </ul>
                <button v-if="$router.user.roleScore >= 2" class="btn btn-sm btn-light" @click="copySerialsToClipboard()"><i class="fa fa-copy"></i> Copy serials to clipboard</button>
              </div>
              <div class="col-12" v-if="order.product_2_serials && order.product_2_serials.length">
                <label>Secondary Product Serials</label>
                <ul>
                  <li v-for="srl in order.product_2_serials" :key="srl">
                    <span :class="{strike: order.serials.find((serial) => serial['product_2'] === srl) || order.nc_products.find((serial) => serial['product_2'] === srl)}">{{ srl }}</span>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9" v-if="order && order.product">
      <div class="card">
        <div class="grid_row serials serials__heading" :style="`grid-template-columns: repeat(${visibleFieldsCount},${100/visibleFieldsCount}%)`">
          <div class="col">
            <button class="btn btn-sm ml-2" @click="enableEditing = !enableEditing" title="Enable editing"><i class="fa fa-edit"></i></button>
            <label>#</label>
          </div>
          <div v-if="isReworkOrder">Ex Serial</div>
          <template v-for="(field) in productFields">
            <div v-if="field.type !== 'AUTO'" :key="field.name" class="thead" :class="{'batch': field.batch && !enableEditing}">
              <div class="d-inline-flex align-items-center">
                <button v-if="field.batch" class="btn btn-sm ml-2" @click="batchScan(field.key)" title="Batch Scan"><i class="fa fa-barcode"></i></button>
                <label>{{ field.name }}</label>
              </div>
            </div>
          </template>
          <template v-if="isDisassemblyOrder">
            <div v-if="disassemblyNotes.length" class="d-inline-flex justify-content-center align-items-center">
              <span>Dettagli Smontaggio</span>
            </div>
          </template>
          <template v-else-if="!isShelfy">
            <div v-if="discardReasons.length" class="d-inline-flex justify-content-center align-items-center">
              <span>Segnalazione NC</span>
              <a class="ml-2 btn btn-sm text-white" href="#nc-products"><i class="fa fa-angle-down"></i></a>
            </div>
          </template>
          <template>
            <div class="d-inline-flex justify-content-center align-items-center">
              <span>Quality Check</span>
              <span class="ml-2 px-1 rounded" :class="order.quality_check_passed_percentage < 2 ? 'bg-danger' : 'bg-success'">{{ order.quality_check_count }}</span>
            </div>
          </template>
        </div>
        <div v-for="(serial, index) in order.serials" :key="index" class="serials grid_row" :class="{'selected': serial.product === selectedSerial }"
          :style="`grid-template-columns: repeat(${visibleFieldsCount},${100/visibleFieldsCount}%)`">
          <div>
            <strong class="col">{{ index + 1 }}</strong>
            <small class="text-nowrap">{{ serial.timestamp | formatTimestamp }}</small>
          </div>
          <template v-if="isReworkOrder">
            <input v-model.trim="serial.ex_serial" :key="index+'-ex_serial'"
              :class="{ ok: serialsCheck[index]['ex_serial'], error: !serialsCheck[index]['ex_serial'] && serialsCheck[index]['ex_serial'] != null }"
              @blur="serialsInputController(serial, index, 'ex_serial')" :readonly="isLoading" class="col" type="text"
            />
          </template>

          <template v-for="(field) in productFields">
            <input v-model.trim="serial[field.key]" :key="index+field.key"
              :disabled="enableEditing && field.type === 'DISABLED' || (field.batch && !enableEditing) || (serialsCheck[index][field.key] && !enableEditing)"
              :required="field.type === 'REQUIRED'" :hidden="field.type === 'AUTO'"
              :class="{ ok: serialsCheck[index][field.key], error: !serialsCheck[index][field.key] && serialsCheck[index][field.key] != null }"
              @blur="serialsInputController(serial, index, field.key)" @focus="activeSerialIndexCheck(index), $event.target.select()" :readonly="isLoading || (order.sap_status === 'closed' && !enableEditing)" class="col" type="text"
            />
          </template>      
          <template v-if="isDisassemblyOrder">    
            <div class="select" v-if="disassemblyNotes.length">
              <select class="select-info form-control form-control-sm ml-1" tabindex="-1" v-model="serial.notes">
                <option disabled selected value="">Seleziona dettagli smontaggio</option>
                <option v-for="item in disassemblyNotes" :key="item">{{ item }}</option>
              </select>
            </div>
          </template>
          <template v-else-if="!isShelfy">    
            <div class="select" v-if="discardReasons.length">
              <select class="select-danger form-control form-control-sm ml-1" tabindex="-1" @change="discard(serial, index, $event.target.value), $event.target.value = false">
                <option disabled selected :value="false">Segnala NC</option>
                <optgroup v-for="c in discardReasons" :key="c.category" :label="c.category">
                  <option v-for="v in c.values" :key="v.name" :value="JSON.stringify({id: v.id, value: v.name, category: c.category, index: c.index})">{{ v.name }}</option>
                </optgroup>
              </select>
            </div>
          </template>
          <div v-if="order.status === 'completed'">
            <div v-if="serial.quality_check">
              <div class="mx-2 px-1 rounded" :class="serial.quality_check.is_passed ? 'bg-success' : 'bg-danger'" @click="showQualityCheckNotes(serial.quality_check.notes)">
                {{ serial.quality_check.is_passed ? 'OK' : 'KO' }}
                <span v-if="serial.quality_check.notes && serial.quality_check.notes.length" :title="serial.quality_check.notes" class="ml-2 fa fa-info"></span>
              </div>
              <small>{{ serial.quality_check.timestamp | formatTimestamp }}</small>
            </div>
            <button v-else-if="serial.product" class="btn btn-sm" @click="startQualityCheck(serial.product)">Start Quality Check</button>
          </div>
        </div>
        <input type="text" placeholder="" @focus="activeSerialIndexCheck(order.serials.length)" />
        <button type="submit" class="btn btn-md btn-outline-secondary btn-block mt-4" :class="{ loading: isLoading }" @click.prevent="updateProductionOrder()">
          <i class="fa fa-save"></i> Save
        </button>
      </div>
      <Modal ref="qualityCheck" title="Quality Check">
        <template #content>
          <div class="row">
            <div class="col-12 col-md-6">
              <label><b>Serial</b></label>
              <h5>{{ qualityCheck.serial }}</h5>
              <br>
              <label><b>Timestamp</b></label><br>
              {{ qualityCheck.timestamp | formatTimestamp }}
            </div>
            <div class="col-12 col-md-6">
              <label><b>Status</b></label>
              <div class="d-inline-flex w-100">
                <input class="position-absolute v-hidden" type="radio" name="is_passed" id="is_passed_ok" v-model="qualityCheck.is_passed" :value="true" />
                <label class="w-100 btn btn-large py-3 px-4 mr-1" :class="qualityCheck.is_passed === true ? 'btn-success':'btn-outline-success'" for="is_passed_ok"><h5 class="mb-0">OK</h5></label>
                <input class="position-absolute v-hidden" type="radio" name="is_passed" id="is_passed_ko" v-model="qualityCheck.is_passed" :value="false" />
                <label class="w-100 btn btn-large py-3 px-4 ml-1" :class="qualityCheck.is_passed === false ? 'btn-danger':'btn-outline-danger'"  for="is_passed_ko"><h5 class="mb-0">KO</h5></label>
              </div>
              <br>
              <label><b>Notes</b></label><br>
              <textarea class="form-control" v-model="qualityCheck.notes"></textarea>
            </div>
            <div class="col-12 text-center">
              <button @click="registerQualityCheckResult()" class="btn btn-primary mt-3"><i class="fa fa-save mr-2"></i>Register quality check result</button>
            </div>
          </div>
        </template>
      </Modal>
      <Modal v-if="order.product.production_instructions" title="Istruzioni pre-assemblaggio" extraClass="modal-warning" ref="newOrderModal" :hideCloseButton="true">
        <template #content>
          <div v-if="isLoading">Generazione seriali in corso...</div>
          <div v-else class="d-inline-flex">
            <div>
              <strong>{{ order.product.name }}</strong>
              <br>
              <strong>SKU: </strong><span>{{ order.product.sku }}</span>
            </div>
            <div v-if="order.product" class="ml-3 bullet" :style="`background-color: ${colors[order.product.commercial_code]}`"></div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-6">
              <p><strong>Selezionare ID operatori:</strong></p>
              <ul>
                <template v-for="(id, index) in operators">
                  <li :key="id">
                    <input v-model="order.operators[index]" class="mr-2" :true-value="id" :id="id" type="checkbox"/>
                    <label :for="id">{{ id }}</label>
                  </li>
                </template>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <p><strong>Approvvigionare le seguenti componenti a bordo linea prima di iniziare l'assemblaggio:</strong></p>
              <ol class="pl-4">
                <template v-for="(pi, index) in order.product.production_instructions.split(';')">
                  <li v-if="pi.length" :key="`pi${index}`">
                    <input v-model="production_instructions_checklist[index]" class="mr-2" :id="`pi${index}`" type="checkbox"/>
                    <label :for="`pi${index}`">{{ pi }}</label>
                  </li>
                </template>
              </ol>
            </div>
          </div>
          <!-- TODO: Color and operators checklist -->
          <button class="btn btn-primary" @click="startProduction()" :disabled="!isLineReady || !hasOperator">Tutto Pronto</button>
        </template>
      </Modal>
      <Modal v-if="isCompleted" title="Ordine completato" extraClass="modal-success" ref="completedOrderModal">
        <template #content>
          <p>
            Ricordati di:
            <ol class="pl-4">
              <li>segnalare qui sotto eventuali componenti o materie prime non conformi</li>
              <li>scaricare la linea di assemblaggio dalle componenti specifiche utilizzate per l'ordine appena terminato</li>
              <li>ripulire la linea da eventuali semilavorati</li>
              <li>nel caso in cui il turno fosse finito, chiudere le scatole delle materie prime a bordo linea</li>
            </ol>
          </p>
          <a href="#nc-components" class="btn btn-danger mr-2" @click="$refs.completedOrderModal.toggle()">Segnala NC</a>
          <button class="btn btn-primary" @click="$refs.completedOrderModal.toggle()">Finito</button>
        </template>
      </Modal>
      <div id="nc-products" class="py-4" v-if="order.nc_products && order.nc_products.length">
        <h6>NC Products</h6>
        <table class="table table-bordered table-responsive table-sm">
          <thead>
            <tr>
              <th>Seleziona</th>
              <th>#</th>
              <th v-for="field in productFields" :key="`f-${field.key}`" :class="{'d-none': field.type === 'AUTO'}">
                {{ field.name }}
              </th>
              <th>Motivazione</th>
              <th>Azione</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(nc, index) in order.nc_products" :key="`ncp-${index}`">
              <td class="text-center">
                <input type="checkbox" v-model="nc.selected" name="ncSelection"/>
              </td>
              <td>
                <div>
                  <strong class="col">{{ index + 1 }}</strong>
                  <small class="text-nowrap">{{ nc.timestamp | formatTimestamp }}</small>
                </div>
              </td>
              <td v-for="field in productFields" :key="`ncp-${index}-${field.key}`" :class="{'d-none': field.type === 'AUTO'}">
                {{ nc[field.key] }}
              </td>
              <td>
                <div class="select">
                  <select class="form-control form-control-sm ml-1" @change="nc.reason = JSON.parse($event.target.value), updateProductionOrder()">
                    <option disabled>Motivazione scarto</option>
                    <optgroup v-for="(c, index) in discardReasons" :key="index+c.category" :label="c.category">
                      <option v-for="(v, index) in c.values" :key="index+v.name" 
                        :value="JSON.stringify({id: v.id, value: v.name, category: c.category, index: c.index})"
                        :selected="JSON.stringify(nc.reason) === JSON.stringify({id: v.id, value: v.name, category: c.category, index: c.index})">
                        {{ v.name }}
                      </option>
                    </optgroup>
                  </select>
                </div>
              </td>
              <td>
                <button 
                  :disabled="isCompleted && $router.user.roleScore <= 2" 
                  class="btn btn-danger" 
                  @click="order.nc_products.splice(index,1), updateProductionOrder()"
                  title="Rimuovi">x</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-group">
          <button class="btn btn-primary btn-outline-primary" @click="createReworkOrder('maintenance')">Create Maintenance Order</button>
          <button class="btn btn-primary btn-outline-primary" @click="createReworkOrder('rework')">Create Rework Order</button>
        </div>
      </div>
      <hr>
      <div id="nc-components" class="py-4">
        <h6>NC Components</h6>
        <table class="table table-bordered table-responsive table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>SKU</th>
              <th>Nome</th>
              <th>Quantità</th>
              <th>Motivazione</th>
              <th>Note</th>
              <th>Azione</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, index) in order.nc_components" :key="`ncc-${index}`">
              <td>
                <div>
                  <strong class="col">{{ index + 1 }}</strong>
                </div>
              </td>
              <td>
                {{ d.sku }}
              </td>
              <td>
                {{ d.name }}
              </td>
              <td>
                <input type="number" v-model="d.quantity" :disabled="isCompleted && $router.user.roleScore <= 2" min="1" @change="updateProductionOrder(true)" />
              </td>
              <td>
                <span v-if="d.reason">{{ d.reason.value }}</span>
              </td>
              <td>
                <textarea v-model="d.notes" @change="updateProductionOrder(true)"></textarea>
                <input type="text" v-model="d.serial" placeholder="Serial" @change="updateProductionOrder(true)" :disabled="isCompleted && $router.user.roleScore <= 2" />
              </td>
              <td>
                <button :disabled="isCompleted && $router.user.roleScore <= 2" class="btn btn-danger" 
                @click="order.nc_components.splice(index,1), updateProductionOrder(true)"
                title="Rimuovi">x</button>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <strong class="col">{{ order.nc_components.length + 1 }}</strong>
                </div>
              </td>
              <td colspan="2">
                <div class="select">
                  <select class="form-control form-control-sm" @click="getComponents()" @change="ncComponent = {...ncComponent, ...JSON.parse($event.target.value)}">
                    <option selected disabled>Seleziona componente</option>
                    <option v-for="(c,index) in components" :key="c.itemCode+index" :value="JSON.stringify({sku: c.itemCode, name:c.itemName})">{{ c.itemCode }} - {{ c.itemName }}</option>
                  </select>
                </div>
              </td>
              <td>
                <input type="number" v-model="ncComponent.quantity" min="1"/>
              </td>
              <td>
                <div class="select">
                  <select class="form-control form-control-sm ml-1" @change="ncComponent.reason = JSON.parse($event.target.value)">
                    <option selected disabled>
                      Seleziona motivazione
                    </option>
                    <optgroup v-for="(c, index) in discardReasons" :key="index+c.category" :label="c.category">
                      <option v-for="(v, index) in c.values" :key="index+v.name"
                        :value="JSON.stringify({id: v.id, value: v.name, category: c.category, index: c.index})">
                        {{ v.name }}
                      </option>
                    </optgroup>
                    <optgroup label="Altro">
                      <option :value="JSON.stringify({id: 0, value: 'Componente non conforme', category: 'Altro', index: 0})">
                        Componente non conforme
                      </option>
                    </optgroup>
                  </select>
                </div>
              </td>
              <td>
                <textarea v-model="ncComponent.notes"></textarea>
                <input type="text" v-model="ncComponent.serial" placeholder="Serial" />
              </td>
              <td>
                <button class="btn btn-primary" @click="order.nc_components.push(ncComponent), ncComponent = { quantity: '1' }, updateProductionOrder(true)" title="Aggiungi">+</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import ProductionService from "@/services/ProductionService";
import SapService from "@/services/SapService";
import downloadCsv from "../utils/downloadCsv";
import productionRules from "../utils/productionRules.json";
import macRevisionList from "../utils/mac_revision_list.json";
import Modal from "../utils/Modal";
import BOM from "../products/BOM";
export default {
  name: "ProductionOrderDetails",
  data() {
    return {
      isLoading: false,
      fields: productionRules.fields,
      operators: productionRules.operators,
      colors: productionRules.colors,
      macR2List: macRevisionList.R2,
      id_number: this.$route.params.id_number,
      order: null,
      labelsCsvData: [],
      serialsCheck: [],
      activeSerialIndex: 0,
      enableEditing: false,
      serialsRegexp: {
        mac: /^[A-Z,0-9]{12}$/,
        techunit: /02([0-9]{8}$)/,
        old_product: /NT([0-9]{7}$)/,
        product: /([0-9]{11}$)/,
        product_2: /([0-9]{11}$)/,
        product_3: /([0-9]{11}$)/,
        mac_extended: /^[0-9]{6}[A-Z,0-9]{12}$/,
        power_supply: /^[a-zA-Z]{1}[0-9]{12}$/,
        power_supply_2: /^[A-Z]{1}[0-9]{2}[A-Z]{2}[0-9]{8}$/,
        battery: /^[0-9]{12}$/,
        battery_2: /^[0-9]{8}-[0-9]{3}$/,
        mainboard: /^[a-zA-Z0-9]{9}.*$/,
        ledboard: /^[a-zA-Z0-9]{9}.*$/,
        // mainboard: /^[a-zA-Z]{4}[0-9]{5}.*$/,
        // ledboard: /^[a-zA-Z]{4}[0-9]{5}.*$/,
        dust_sensor: /([0-9]{6}-[0-9]{2}$)/,
        fan: /^.*$/,
        proximity_sensor: /^.*$/
      },
      production_instructions_checklist: [],
      discardReasons: [],
      disassemblyNotes: [],
      ncProductsSelection: [],
      ncComponent: {
        quantity: '1'
      },
      components: [],
      selectedSerial: false,
      qualityCheck: {
        serial: null,
        timestamp: null,
        is_passed: null,
        notes: null
      }
    };
  },
  components: { downloadCsv, Modal, BOM },
  sockets: {
    async productionOrderUpdated(updatedOrder) {
      console.log(
        "[socket.io] Production order has been updated. " + updatedOrder.id_number
      )
      await this.getProductionOrder(this.id_number)
    }
  },
  computed:{
    isCompleted(){
      return this.order.status === 'completed'
    },
    isLineReady(){
      return this.production_instructions_checklist.every(c => c)
    },
    hasOperator(){
      return this.order.operators.filter(o => o && o.length).length > 0
    },
    productFields(){
      return Object.keys(this.fields).map((field) => { 
          if (this.fields[field] && this.fields[field][this.order.product.commercial_code] !== 'HIDDEN') { 
            let isBatch = this.fields[field].batch
            if (this.fields[field].batch_exclude?.includes(this.order.product.commercial_code)) {
              isBatch = false
            }
            return {
              key: field, 
              name: this.fields[field].name, 
              type: this.fields[field][this.order.product.commercial_code], 
              batch: isBatch
            } 
          } 
        }).filter( Boolean )
    },
    isReworkOrder(){
      return this.order.typology === 'rework'
    },
    isDisassemblyOrder(){
      return this.order.typology === 'disassembly'
    },
    visibleFieldsCount(){
      return this.productFields.filter(f => f.type !== 'AUTO').length + 2 + (this.isReworkOrder ? 1 : 0)
    },
    product() {
      return this.order.product.commercial_code
    },
    isShelfy(){
      return this.product === 'SHL0'
    }
  },
  async mounted() {
    this.selectedSerial = this.$route.query.serial ?? false
    await this.getProductionOrder(this.id_number)
    if (this.order.status === 'new' && this.order.product.production_instructions && (this.order.typology === 'production' || this.isReworkOrder)) {
      this.$refs.newOrderModal.toggle()
    }
    this.discardReasons = productionRules.discardReasons.reduce((a, c) => {
        const values = c.values.filter((l)=> l.products.includes(this.order.product.commercial_code))
        if (values.length) { a.push({ ...c, values }) }
        return a
      },[])
    if (this.isDisassemblyOrder) {
      this.disassemblyNotes = productionRules.disassemblyNotes[this.order.product.commercial_code]
    }
    this.getComponents()
  },
  methods: {
    async getProductionOrder(id) {
      await ProductionService.getProductionOrder(id).then((response) => {
        this.order = response.data;
        this.getSerialsCount(this.order.product);
        this.setLabelsCsvData(this.order);
      });
      
      const lineItemsCount = this.order.product.production_instructions.split(';').filter(p => p.length).length
      this.production_instructions_checklist = Array(lineItemsCount).fill(false);
    },
    async startProduction(){
      this.isLoading = !this.isLoading
      this.order.operators = this.order.operators.filter(o => o && o.length);
      await ProductionService.startProductionOrder(this.order.id_number, this.order)
        .then(async() => {
          await this.getProductionOrder(this.order.id_number)
          this.$refs.newOrderModal.toggle()
        })
        .catch(()=>{
          console.log('Errore durante la generazione dei seriali.')
        })
      this.isLoading = !this.isLoading
    },
    getSerialsCount(product) {
      !product.quantity_actual ? product.quantity_actual = product.quantity : ''
      let productsCount = product.quantity_actual
      let n = productsCount - this.order.serials.length;
      
      const requiredFields = {}
      const serialFields = {}
      Object.keys(this.fields).forEach((field) => { 
        if (this.fields[field] && this.fields[field][this.order.product.commercial_code] === 'REQUIRED') { 
          serialFields[field] =  null 
          requiredFields[field] =  null 
        }
        if (this.fields[field] && this.fields[field][this.order.product.commercial_code] === 'AUTO') { 
          serialFields[field] =  null 
        } 
      })

      for (let i = 0; i < n; i++) {
        this.order.serials.push({
          ...requiredFields,
          sku: product.sku
        }); 
      }

      this.serialsCheck = [];
      for (let i = 0; i < productsCount; i++) {
        this.serialsCheck.push({...requiredFields})
        Object.keys(requiredFields).forEach((field) => {
          this.serialsInputController(this.order.serials[i], i, field)
        })
      }
    },
    async serialsInputController(serial, index, field) {
      if (field && (field === 'product' || field === 'product_2' || field === 'product_3')) {
        if (serial[field]?.toLowerCase().includes("https://link.vitesy.com/s/")) {
          serial[field] = serial[field]?.toLowerCase().replace("https://link.vitesy.com/s/","")
        }
        if(this.isDisassemblyOrder && field === 'product' && (this.serialsRegexp.product.test(serial[field]) || this.serialsRegexp.old_product.test(serial[field]))) {
          const response = await this.getProductProductionData(serial[field])
          if (response?.data?.serials) {
            let exSerial = response.data.serials
            delete exSerial.timestamp
            Object.assign(serial, exSerial) 
            this.serialsCheck[index][field] = true
          } else {
            this.serialsCheck[index][field] = false
          }
        }
      }
      if (field && field === 'product_3' && this.serialsRegexp.product_3.test(serial.product_3)) {
        const response = await this.getProductProductionData(serial.product_3)
        if (response?.data?.serials && response.data.product.color === this.order.product.color && response.data.product.plug === this.order.product.plug) {
          serial.mac = response.data.serials.mac
          serial.power_supply = response.data.serials.power_supply
          this.serialsCheck[index].product_3 = true
        } else {
          this.serialsCheck[index].product_3 = false
        }
      } else if (field && field === 'ex_serial' && this.serialsRegexp.product.test(serial.ex_serial)) {
        const response = await this.getProductProductionData(serial.ex_serial)
        // if (response?.data?.serials && response.data.product.color === this.order.product.color && response.data.product.plug === this.order.product.plug) {
        if (response?.data?.serials) {
          let exSerial = response.data.serials
          delete exSerial.product
          delete exSerial.timestamp
          Object.assign(serial, exSerial) 
          this.serialsCheck[index].ex_serial = true
        } else {
          this.serialsCheck[index].ex_serial = false
        }
      } else if (field && field === 'mac') {
        if (!serial.mac || !serial.mac.length) { this.serialsCheck[index].mac = null }
        else if (this.serialsRegexp.mac_extended.test(serial.mac.toUpperCase())) {
          serial.mac = serial.mac.toUpperCase().substr(6, serial.mac.length)
          this.serialsCheck[index].mac = true
        } else if (this.serialsRegexp.mac.test(serial.mac.toUpperCase())) {
          this.serialsCheck[index].mac = true
        } else {
          this.serialsCheck[index].mac = false 
        }

        // TEMP REV2 FEBREZE CHECK
          if (serial.mac && serial.mac.length && this.order.product.sku === 'FZ00AA00_A') {
            if (this.macR2List.includes(serial.mac)) {
              this.serialsCheck[index].mac = true
            } else {
              this.serialsCheck[index].mac = false
              alert('ATTENZIONE: Questo MAC address non corrisponde ad una scheda Revisione 2 richiesta per questo prodotto!')
            }
          }
      } else if (field && field === 'power_supply') {
        serial[field]?.length ? this.serialsRegexp.power_supply.test(serial[field]) || this.serialsRegexp.power_supply_2.test(serial[field]) ? (this.serialsCheck[index][field] = true) : (this.serialsCheck[index][field] = false) : (this.serialsCheck[index][field] = null)
      } else if (field && field === 'battery') {
        serial[field]?.length ? this.serialsRegexp.battery.test(serial[field]) || this.serialsRegexp.battery_2.test(serial[field]) ? (this.serialsCheck[index][field] = true) : (this.serialsCheck[index][field] = false) : (this.serialsCheck[index][field] = null)
      } else if (field && (field === 'product')) {
        serial[field]?.length ? (this.serialsRegexp[field].test(serial[field]) || this.serialsRegexp['old_product'].test(serial[field])) && (this.isDisassemblyOrder || this.order.product_serials.includes(serial[field])) ? (this.serialsCheck[index][field] = true) : (this.serialsCheck[index][field] = false) : (this.serialsCheck[index][field] = null)
      } else if (field && field === 'product_2') {
        serial[field]?.length ? this.serialsRegexp[field].test(serial[field]) && this.order.product_2_serials.includes(serial[field]) ? (this.serialsCheck[index][field] = true) : (this.serialsCheck[index][field] = false) : (this.serialsCheck[index][field] = null)
      } else if (field && field === 'fan') {
        if (serial[field]?.toLowerCase().includes("http://sunon.bz/?")) {
          serial[field] = serial[field]?.toLowerCase().replace("http://sunon.bz/?","")
        }
        serial[field]?.length ? this.serialsRegexp[field].test(serial[field]) ? (this.serialsCheck[index][field] = true) : (this.serialsCheck[index][field] = false) : (this.serialsCheck[index][field] = null)
      } else {
        serial[field]?.length ? this.serialsRegexp[field].test(serial[field]) ? (this.serialsCheck[index][field] = true) : (this.serialsCheck[index][field] = false) : (this.serialsCheck[index][field] = null)
      }


    },
    async getProductProductionData(serial){
      const productProductionData = await ProductionService.getProductSerials(serial)
      return productProductionData
    },
    activeSerialIndexCheck(index){
      if (this.activeSerialIndex !== index) {
		    const rowCompleted = Object.values(this.order.serials[index - 1]).every((val) => val || (val && val.length))
        if (rowCompleted) {
          !this.order.serials[index - 1].timestamp ? this.order.serials[index - 1].timestamp = new Date() : ''
        }
        this.updateProductionOrder()
      }
      this.activeSerialIndex = index
    },
    async updateProductionOrder(disabledCompletedModal) {
      this.isLoading = !this.isLoading
      await ProductionService.updateProductionOrder(
        this.order.id_number,
        this.order
      ).then(() => {
        this.isLoading = !this.isLoading
        this.getProductionOrder(this.id_number).then(()=>{
          if (this.isCompleted && !disabledCompletedModal) {
            this.$refs.completedOrderModal.toggle()
          }
        })
      })
    },
    setLabelsCsvData(order) {
      let labelData = []
      if (order.product_serials.length) {
        labelData = order.product_serials.map((serial, index) => {
          return {
            commercial_code: order.product.commercial_code,
            sku: order.product.sku,
            serial: serial,
            batch: order.date.substring(0, 7),
            commercial_name: order.product.commercial_name,
            sku_2: order.product_2 ? order.product_2.sku : order.product.sku,
            serial_2: order.product_2 ? order.product_2_serials[index] : serial,
            color: order.product.color,
            ean: order.product.ean,
            qrcode: `https://link.vitesy.com/s/${serial}`
          }
        })
      }
      this.labelsCsvData = labelData
    },
    batchScan(field){
      let serials = [...this.order.serials]
      let batchQuantity = 0
      const batchSerial = prompt(`Scan the "${field}" batch serial`)
      if (batchSerial){
        const serialOk = this.serialsRegexp[field].test(batchSerial) ? true: false
        if (serialOk) {
          batchQuantity = prompt(`How many "${field}" fields do you want to fill with serial "${batchSerial}"?`)
          const emptyIndex = serials.findIndex((s) => !s[field] || !s[field].length)
          if (batchQuantity && Number(batchQuantity > 0)){
            for (let i = 0; i < batchQuantity; i++) {
              if (!serials[i + emptyIndex]) break
              serials[i + emptyIndex][field] = batchSerial
            }
            this.order.serials = serials
          } else {
            alert('Ops, qualcosa è andato storto, riprova.')
          }
        } else {
          alert('Ops, il codice scansionato non sembra essere corretto, riprova.')
        }
      }
    },
    clearRow(serial){
      for (var key in serial ) {
        delete serial._id
        delete serial.timestamp
        delete serial.active
        if (key !== 'sku') { serial[key] = null }
      }
    },
    discard(serial, index, reasonString) {
      if (confirm('Are you sure you want to discard this product?')){
      const reason = JSON.parse(reasonString)
      if (reason.category === 'Installazione FW' || reason.category === 'Collaudo funzionale') {
        this.order.nc_products.push({...serial, reason: reason, timestamp: new Date(), quantity: 1 })
        this.order.serials.splice(index, 1)
        this.order.product.quantity_actual--
      } else {
        const ncComponent = this.getComponentByDiscardReason(reason.id)
        this.order.nc_components.push({
          sku: ncComponent.itemCode,
          name: ncComponent.itemName,
          quantity: 1,
          reason: reason,
          notes: '',
          serial: reason.value === 'Alimentatore - componente non conforme' ? serial.power_supply : '' 
        })
        reason.category !== 'TAMU' ? this.clearRow(serial) : ''
      }
      this.updateProductionOrder()
      }
    },
    getComponentByDiscardReason(id){
      return this.components.find(c => c.discardReasonId === id)
    },
    async getComponents(){
      if (!this.components.length) {
        const bom = productionRules.bom.filter(p => p.skus.includes(this.order.product.sku))[0]?.items ?? []
        if (bom.length) {
          this.components = bom
        } else {
          // const response = await SapService.getProducts({semilavorato_da_non_esplodere: 'Y'})
          // this.components = response.data
          const response = await SapService.getProducts({itemGroup: 'Materie prime'})
          this.components = response.data.filter(c => c.productFamily === this.order.product.family)
        }
      }
    },
    async createReworkOrder(typology){
      const serials = this.order.nc_products.filter(p => p.selected)
      if (!serials.length) {
        alert('Select at least 1 NC product to create a maintenance order.')
      } else {
        const ncProductsComponents = this.order.nc_products.map((nc) => {
          const ncComponent = this.getComponentByDiscardReason(nc.reason.id)
          if (ncComponent) {
            return {
              sku: ncComponent.itemCode,
              name: ncComponent.itemName,
              quantity: 1,
              reason: nc.reason,
              notes: '',
              serial: nc.product
            }
          }
        }).filter(n => n !== undefined)
        const newIdNumber = `${this.order.id_number}_${typology === 'maintenance' ? 'MT' : 'RW'}`
        const newOrder = {
          id_number: newIdNumber,
          product: {...this.order.product, quantity: serials.length, quantity_actual: serials.length },
          serials: serials,
          typology: typology,
          nc_components: ncProductsComponents
        }
        await ProductionService.addProductionOrder(newOrder)
        this.$router.push(newIdNumber)
        this.$router.go()
      }
    },
    copySerialsToClipboard(){
      const serialsList = this.order.serials.map(s => s.product)
      this.copyToClipboard(serialsList.join('\t'))
    },
    startQualityCheck(serial){
      this.qualityCheck.serial = serial
      this.qualityCheck.timestamp = new Date()
      this.$refs.qualityCheck.toggle()
    },
    async registerQualityCheckResult(){
      const serialIndex = this.order.serials.findIndex(s => s.product === this.qualityCheck.serial)
      if (serialIndex >= 0) {
        this.order.serials[serialIndex].quality_check = this.qualityCheck
        await this.updateProductionOrder(true)
        this.qualityCheck = {
          serial: null,
          timestamp: null,
          is_passed: null,
          notes: null
        }
        this.$refs.qualityCheck.toggle()
      } else {
        alert('Serial not found.')
      }
    },
    showQualityCheckNotes(notes) {
      if(notes && notes.length) {
        alert(`Quality Check Notes\n\n${notes}`)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.details > div > div {
  margin-bottom: 0.5rem;
}
.notes {
  border: 0.2rem solid $ups;
  padding: 0.5rem;
  margin-top: 1rem;
}
.status {
  text-transform: capitalize;
  border-radius: 1.5rem;
  padding: 0.2rem 0.5rem;
  &.completed {
    background-color: $green;
    color: #fff;
  }
  &.in-progress {
    background-color: $ups;
  }
  &.cancelled {
    background-color: $red;
    color: #fff;
  }
  &.new {
    background-color: $clairy;
    color: #fff;
  }
}
.serials {
  &.selected {
    background: $clairy;
  }
  .pattern {
    font-size: 0.7rem;
    color: rgba(#fff, 0.2);
  }
  margin: 0;
  text-align: center;
  align-items: center;
  padding-right: 0.5rem;
  &__heading {
    background-color: $blue;
    color: #fff;
    padding-right: 0;
    margin-bottom: 1rem;
    > div {
      border-right: 1px solid rgba(#fff, 0.2);
    }
    label {
      margin: 0;
      padding: 0.5rem;
    }
  }
  input {
    margin: 0.25rem 0;
    border-left: 0.25rem solid #000;
    &.ok {
      border-color: $green;
    }
    &.error {
      border-color: $red;
    }
  }
}
.product {
  margin-top: 1rem;
  width: 100%;
  tr {
    &._id {
      display: none;
    }
    &.production_instructions { display: none; font-weight: 700; background-color: $ups; }
    td {
      padding: 0.2rem 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      b {
        text-transform: capitalize;
      }
    }
  }
}
.print {
  color: $blue;
  border: 1px solid $blue;
  padding: 1.5rem 3rem;
  box-shadow: 0 0 0 0.5rem #fff;
  width: calc(100% - 2rem);
  margin: 1rem;
  &:hover {
    color: #fff;
    background-color: $blue;
    border-color: #fff;
    box-shadow: 0 0 0 0.5rem $blue;
  }
  i {
    //display: block;
    margin: 0.2rem;
    margin-bottom: 1rem;
  }
}
ul {
  columns: 2;
  .strike {
    text-decoration: line-through;
  }
}
input {
  padding: 0.2rem.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s;
  &:read-only {
    background-color: rgba($green, 0.5);
  }
  &:disabled {
    background-color: #f0f0f0;
  }
}
.grid_row {
  display: grid;
}
.thead {
  height: 100%;
  display: flex;
  justify-content: center;
  &.batch {
    background-color: #343a3f;
  }
}

select.select-danger {
  border-color: $red;
  color: $red;
}
</style>
